import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SearchIcon({
  fill = "none",
  stroke = "#333333",
  width = 20,
  height = 20,
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      {...rest}
    >
      <rect x="0.5" y="0.5" width="19" height="19" stroke={stroke} />
    </SvgIcon>
  );
}
