import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Typography } from "@material-ui/core";
import Image from "material-ui-image";
import { useSelector } from "react-redux";
import theme from "../../theme";
import LoadingCard from "../card/loading-card";
import { getAssetSlug, getSpaceSlug } from "../../utils";
import Link from "../link";
import { getSpacePriceConfigFromSpot, getSpacePriceFromConfig } from "../../utils/spots";
import Card from "../card/space-list-card";

const useStyles = makeStyles((theme) => ({
  breadcumbs: {
    paddingBottom: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      "& > nav > ol > li": {
        display: "none",
      },
      "& > nav > ol > li:nth-last-of-type(1)": {
        display: "flex",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& > nav > ol > li:nth-last-of-type(2)": {
        display: "flex",
      },
      "& > nav > ol > li:nth-last-of-type(3)": {
        display: "flex",
      },
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  title: {
    borderBottom: theme.borders[6],
    paddingBottom: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  emptySpace: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  noLinkStyle: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

function SpaceList({ region, spaces, asset, spots, currentPage, lastPage, loadingSpaces, onNextPage, filters }) {
  const classes = useStyles(theme);
  const [height, setHeight] = useState(0);

  const selectedSpot = spots.find((spot) => spot.slug === filters.spot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(filters.spot);

  const hasSpaceDimensionsFeature = useSelector((state) => state.misc.featureFlags["flag.ops.space_dimensions"]);

  const assetMapAttachment =
    asset &&
    asset.attachments &&
    asset.attachments.length &&
    asset.attachments.find((attachment) => attachment.meta.type === "map");

  const resizeBox = () => {
    const box = document.getElementById("spaces-list");
    if (box) {
      const height = window.innerHeight - 160;
      setHeight(height);
      if (box.scrollTop === 0 || box.scrollTop - box.scrollHeight < 50) {
        box.scrollTop = box.scrollHeight;
      }
    }
  };

  const handleMouseEnter = (id) => {
    const marker = document.getElementById(`marker-${id}`);
    const span = document.getElementById(`marker-span-${id}`);

    if (marker && span) {
      marker.classList.add("price-marker-hover");
      span.classList.add("price-marker-span-hover");
    }
  };

  const handleMouseLeave = (id) => {
    const marker = document.getElementById(`marker-${id}`);
    const span = document.getElementById(`marker-span-${id}`);

    if (marker && span) {
      marker.classList.remove("price-marker-hover");
      span.classList.remove("price-marker-span-hover");
    }
  };

  useEffect(() => {
    resizeBox();
    window.onresize = resizeBox;
  }, []);

  const spotTitle = filters.spot ? (
    filters.spotTitle || (
      <>
        {filters.spot === "advertising" && <Trans>Advertising</Trans>}
        {filters.spot === "promotion" && <Trans>Promotion</Trans>}
        {filters.spot === "retail" && <Trans>Retail & Others</Trans>}
      </>
    )
  ) : (
    <></>
  );

  return (
    <>
      {filters.spot && (
        <>
          <Box className={classes.breadcumbs}>
            <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
              <Link color="inherit" to="/">
                <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                  Home
                </Typography>
              </Link>
              {region.name && (
                <Link color="inherit" to={`/region-assets/${region.id}/`}>
                  <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                    {region.name}
                  </Typography>
                </Link>
              )}
              <Link color="inherit" to={`/asset-spots/${getAssetSlug(asset)}/`}>
                <Typography variant="h6" style={{ color: theme.palette.gray71 }}>
                  {asset?.name}
                </Typography>
              </Link>
              <Typography variant="h6" style={{ color: theme.palette.black }}>
                {spotTitle}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box className={classes.title}>
            <Link to={`/asset-spots/${getAssetSlug(asset)}`} style={{ textDecoration: "none" }}>
              <Typography variant="h2">{spotTitle}</Typography>
            </Link>
          </Box>
        </>
      )}
      <Box
        mt={1}
        pb={4}
        display="flex"
        flexDirection="column"
        justifyContent="left"
        alignItems="center"
        width="100%"
        style={{ overflowY: "auto", height }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignContent="start"
          flexWrap="wrap"
          width="100%"
        >
          {!spaces.length && loadingSpaces === "success" ? (
            <Box className={classes.emptySpace}>
              <Typography variant="body1">
                <Trans>No se encontraron espacios disponibles</Trans>
              </Typography>
            </Box>
          ) : (
            spaces.map((s) => {
              return (
                <Box
                  key={s.id}
                  ml={1 / 2}
                  mr={1 / 2}
                  mt={1}
                  onMouseEnter={() => {
                    handleMouseEnter(s.id);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave(s.id);
                  }}
                >
                  <Card
                    id={s.id}
                    images={s.images}
                    title={s.title}
                    size={s.size}
                    price={getSpacePriceFromConfig(s, priceConfig)}
                    showPrice={asset?.config?.show_space_price}
                    priceConfig={priceConfig}
                    to={`/space/${getSpaceSlug(s)}/?spot=${filters.spot}&start=${filters.start}&end=${filters.end}`}
                    areaUnit={s.area_unit}
                    dimensions={s.dimensions}
                    hasSpaceDimensionsFeature={hasSpaceDimensionsFeature}
                  />
                </Box>
              );
            })
          )}
        </Box>
        {(loadingSpaces === "loading" || !asset) && (
          <Box mt={1} mb={2} display="flex" flexDirection={["column", "row"]} alignItems="center">
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Box>
        )}
        {loadingSpaces === "success" && currentPage < lastPage && (
          <Box mt={2}>
            <Button onClick={onNextPage} variant="contained" color="primary">
              <Trans>MOSTRAR MÁS</Trans>
            </Button>
          </Box>
        )}

        {assetMapAttachment && (
          <Box style={{ width: "100%", height: "100%", minHeight: "600px" }}>
            <Image
              src={assetMapAttachment.file_url}
              style={{
                height: "100%",
                padding: "0",
                width: "100%",
              }}
              imageStyle={{
                objectFit: "contain",
                height: "100%",
                minHeight: "600px",
                width: "100%",
                borderRadius: "5px",
              }}
            />
          </Box>
        )}

        {asset && (
          <Box display="flex" flexDirection="column" pt={4} pl={2} pr={2} gridGap={4} width="100%">
            <Typography variant="h2" style={{ color: theme.palette.black }}>
              {asset.seo_title}
            </Typography>
            <Typography variant="body1">{asset.seo}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default SpaceList;
