import React from "react";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { getSpaceSlug, limitStringChars } from "../../utils";
import { SpacePriceConfigTranslations } from "../../utils/spots";
import { formatCurrencyForTenant } from "../../utils/currencyUtils";
import Link from "../link";
import CloseIcon from "../../icons/close-icon";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  mapCard: {
    display: "none",
    flexDirection: "row",
    position: "absolute",
    width: "80%",
    bottom: "-50%",
    left: "5%",
    height: "15%",
    borderRadius: "5px",
    backgroundColor: th.palette.white,
  },
}));

function MapCard({ space, status, spot, price, priceConfig, showSpacePrice }) {
  const { i18n } = useLingui();
  const classes = useStyles({ ...theme });

  return (
    <Box id="map-card" className={classes.mapCard}>
      <Link
        target="_blank"
        to={`/space/${getSpaceSlug(space)}?spot=${spot}`}
        style={{ display: "flex", width: "100%" }}
      >
        <Image
          disableSpinner
          color={theme.palette.darkGray}
          src={space.attachments?.length > 0 ? space.attachments[0].url : "/logo.svg"}
          style={{
            height: "100%",
            padding: "0",
            width: "30%",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
          imageStyle={{
            height: "100%",
            width: "100%",
            cursor: "pointer",
            objectFit: "cover",
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          width="60%"
          style={{ cursor: "pointer" }}
          p={1}
        >
          {status === "loading" && (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
              <CircularProgress />
            </Box>
          )}
          {status === "success" && (
            <>
              <Typography variant="subtitle1">{limitStringChars(space.title, 25)}</Typography>
              {showSpacePrice && price && (
                <Typography variant="subtitle1" color="primary">
                  <Trans>Desde</Trans>{" "}
                  {`${formatCurrencyForTenant(price)}/${i18n._(SpacePriceConfigTranslations[priceConfig])}`}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Link>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={1}
        style={{ cursor: "pointer" }}
        onClick={() => {
          const mapCard = document.getElementById("map-card");
          mapCard.style.bottom = "-50%";
          mapCard.style.display = "none";
        }}
      >
        <CloseIcon width="15px" height="15px" />
      </Box>
    </Box>
  );
}

export default MapCard;
