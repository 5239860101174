import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Map from "../../containers/spaces-list/map";
import Filters from "../../containers/spaces-list/filters";
import FiltersMobile from "../../containers/spaces-list/filters-mobile";
import SpaceList from "../../containers/spaces-list/space-list";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
  },
}));

function SpaceListWrapper({ spaces = [], asset = {} }) {
  const classes = useStyles(theme);
  const [height, setHeight] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const currentAsset = useSelector((state) => state.misc.currentAsset);

  const assetMapAttachment =
    currentAsset &&
    currentAsset.attachments &&
    currentAsset.attachments.length &&
    currentAsset.attachments.find((attachment) => attachment.meta.type === "map");

  const resizeBox = () => {
    const box = document.getElementById("spaces-list");
    if (box) {
      const height = window.innerHeight - (window.screen.width < 600 ? 148 : 84);
      setHeight(height);
      if (box.scrollTop === 0 || box.scrollTop - box.scrollHeight < 50) {
        box.scrollTop = box.scrollHeight;
      }
    }
  };

  useEffect(() => {
    resizeBox();

    window.addEventListener("resize", resizeBox);
    return () => {
      window.removeEventListener("resize", resizeBox);
    };
  }, []);

  useEffect(() => {
    resizeBox();
  }, [asset]);

  return (
    <Box id="spaces-list" className={classes.container}>
      <Grid container direction="row">
        <Grid item xs={12} md={assetMapAttachment ? 12 : 8} lg={assetMapAttachment ? 12 : 8}>
          <Box width="100%">
            <Hidden mdUp>
              <FiltersMobile
                showMap={showMap}
                handleShowMap={(show) => {
                  setShowMap(show);
                }}
              />
            </Hidden>
            <Hidden xsDown>
              <Filters />
            </Hidden>
          </Box>
          <Box position="relative" pb={4}>
            <SpaceList spaces={spaces} asset={asset} />
            {xs && showMap && !assetMapAttachment && (
              <Box id="mobile-spaces-map" position="absolute" top="0" left="0" zIndex="999" width="100%">
                <Map showMap={false} height={height} />
              </Box>
            )}
          </Box>
        </Grid>

        {!assetMapAttachment && (
          <Grid item md={4} lg={4} style={{ height: "100%" }}>
            <Hidden xsDown>
              <Map width="100%" height={height} />
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default SpaceListWrapper;
