import React from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "../../icons/checkbox-icon";
import CheckBoxCheckedIcon from "../../icons/checkbox-checked-icon";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: theme.borders[1],
    height: "18.854px",
    width: "18.854px",
  },
}));

function CheckedBox() {
  const classes = useStyles(theme);
  return (
    <Box className={classes.box}>
      <CheckIcon
        style={{ width: 18, height: 18, color: theme.palette.black }}
      />
    </Box>
  );
}

function CustomCheckBox({ ...rest }) {
  return (
    <Checkbox icon={<CheckBoxIcon />} checkedIcon={<CheckedBox />} {...rest} />
  );
}

export default CustomCheckBox;
