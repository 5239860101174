import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { isDateValid } from "/src/utils/dateUtils";
import Navbar from "/src/containers/navbar";
import { useQuery } from "/src/utils/index";
import SpacesListWrapper from "/src/components/spaces-list/space-list-wrapper";
import { useLocalization } from "gatsby-theme-i18n";
import { setCurrentAsset, getSpotsByAssetThunk } from "../../redux/actions/misc-actions";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import { transformAsset } from "/src/redux/reducers/space-reducer";
import config from "/src/config.js";
import SEO from "../../components/seo";
import { getAssetSlug } from "../../utils";
import { MAX_PRICE, MAX_SIZE } from "../../redux/reducers/space-list-reducer";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import SetupContainer from "/src/setup-container";

const AssetsPage = ({ data }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const localization = useLocalization();
  const asset = transformAsset({ ...data.asset, id: data.asset.asset_id });
  const assets = data.allAsset.nodes.map((a) => transformAsset({ ...a, id: a.asset_id }));
  const spaceTypes = data.allSpaceTypes.nodes.map((s) => ({
    ...s,
    id: s.space_type_id,
  }));
  const spaceFeatures = data.allSpaceFeatures.nodes.map((s) => ({
    ...s,
    id: s.space_feature_id,
  }));
  const filtersUrl = useSelector((state) => state.spaceList.filtersUrl);
  const newAsset = useSelector((state) => state.spaceList.filters.asset);
  const spacesListStatus = useSelector((state) => state.spaceList.status);
  const currentAsset = newAsset ? assets.find((a) => a.id === newAsset) : asset;

  useEffect(() => {
    const filters = getFiltersFromUrl({
      asset,
      spaceTypes,
      spaceFeatures,
      query,
    });
    dispatch(addSpacesFilterThunk(filters));
    dispatch(setCurrentAsset(asset));
    dispatch(getSpotsByAssetThunk(asset.id));
  }, []);

  useEffect(() => {
    if (spacesListStatus === "success") {
      const currentAsset = newAsset ? assets.find((a) => a.id === newAsset) : asset;
      const newPath = localization.localizedPath({
        ...localization,
        path: `/active-spaces/${getAssetSlug(currentAsset)}/?${filtersUrl}`,
      });
      if (typeof window !== "undefined" && window.history.pushState) {
        window.history.replaceState({}, "", newPath);
      }
    }
  }, [spacesListStatus]);

  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - ${asset.city.name} ${asset.name}`}
        description={asset.description}
        image={encodeURI(asset.thumb_url) || ""}
      />
      <BacThemeProvider assetId={currentAsset.asset_id}>
        <Navbar />
        <SpacesListWrapper asset={currentAsset} />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};
const getFiltersFromUrl = ({ asset, spaceTypes, spaceFeatures, query }) => {
  const sizeMin = 0;
  const sizeMax = MAX_SIZE;
  const priceMin = 0;
  const priceMax = MAX_PRICE;
  const sizeStart = query.get("sizeStart");
  const sizeEnd = query.get("sizeEnd") || sizeMax;
  const priceStart = query.get("priceStart");
  const priceEnd = query.get("priceEnd") || priceMax;
  const urlFeatures = query.get("features");
  const urlTypes = query.get("types");
  const urlSpot = query.get("spot");
  const spotTitle = query.get("spotTitle");
  const start = query.get("start");
  const end = query.get("end");

  return {
    start: isDateValid(start) ? start : "",
    end: isDateValid(end) ? end : "",
    sizeStart: Math.max(sizeMin, sizeStart),
    sizeEnd: Math.min(sizeMax, sizeEnd),
    priceStart: Math.max(priceMin, priceStart),
    priceEnd: Math.min(priceMax, priceEnd),
    features: urlFeatures
      ? urlFeatures
          .split(",")
          .filter((u) => !!u)
          .map((name) => spaceFeatures.find((u) => u.name === name))
      : [],
    types: urlTypes
      ? urlTypes
          .split(",")
          .filter((u) => !!u)
          .map((name) => spaceTypes.find((u) => u.name === name))
      : [],
    spot: urlSpot || "",
    spotTitle: spotTitle || "",
    asset,
  };
};

export default AssetsPage;

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query ($id: String) {
    asset(id: { eq: $id }) {
      id
      asset_id
      address
      description
      latitude
      longitude
      logo_light_url
      logo_dark_url
      name
      styles
      thumb_url
      type
      city {
        name
      }
      config {
        show_space_price
      }
    }
    allAsset {
      nodes {
        name
        asset_id
        city {
          name
        }
        config {
          show_space_price
        }
      }
    }
    allSpaceTypes {
      nodes {
        name
        space_type_id
      }
    }
    allSpaceFeatures {
      nodes {
        name
        space_feature_id
      }
    }
    allSpace(filter: { asset_id_gql: { eq: $id } }) {
      nodes {
        asset {
          name
          address
          city {
            id
            name
          }
        }
        description
        display_price
        id
        space_id
        level
        latitude
        longitude
        searchable
        space_type_id
        space_sub_type_id
        title
        size
        attachments {
          description
          id
          role
          space_id
          type
          url
        }
        week_day_price
      }
    }
  }
`;
